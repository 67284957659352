import React from "react";
import image from "../../assets/logo.jpg";
import { Link, useNavigate } from "react-router-dom";
import { isAuthenticate, removeToken } from "../../utils/auth";

const Header = () => {
  const navigate = useNavigate();
  const logoutHandler = () => {
    removeToken();
    navigate(0);
  };
  return (
    <header>
      <div className="wrapper">
        <div className="title-wrapper">
          <img src={image} />
          <h3>Owwi Todo</h3>
        </div>
        {!isAuthenticate() && <Link to="/login" title="Login to keep your data everywhere">Login</Link>}
        {isAuthenticate() && (
          <button onClick={logoutHandler} title="Logout">
            <i className="fa-solid fa-right-from-bracket" />
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
