import React, { useState } from "react";
import { ConfirmPopup } from "../../ConfirmPopup/ConfirmPopup";

export const TodoItem = ({ item, onUpdateStatus }) => {
  const [popup, setPopup] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(item.title);
  const markCompletedHandler = () => {
    onUpdateStatus(item.id, "completed");
  };

  const markIncompleteHandler = () => {
    onUpdateStatus(item.id, "incomplete");
  };

  const markInProgressHandler = () => {
    onUpdateStatus(item.id, "in-progress");
  };

  const deleteHandler = () => {
    onUpdateStatus(item.id, "delete");
  };

  const updateTitleHandler = () => {
    onUpdateStatus(item.id, "update", value);
    setIsEdit(false);
  };

  const status = item.status
    .replace(/-/g, " ")
    .replace(/\b\w/g, (l) => l.toUpperCase());

  return (
    <div className={`todo-item ${item.status}`}>
      {popup && (
        <ConfirmPopup
          message={"Are you sure to delete this task?"}
          onConfirm={deleteHandler}
          onCancel={() => setPopup(false)}
        />
      )}
      <div className="title" title={item.title}>
        {!isEdit && item.title}
        {isEdit && (
          <input
            className="input-title"
            placeholder="Enter title..."
            value={value}
            onBlur={updateTitleHandler}
            onChange={(event) => setValue(event.target.value)}
            onKeyDown={(event) => {
              event.key === "Enter" && updateTitleHandler();
            }}
          />
        )}
      </div>
      <div className="list-group">
        <div className="edit-button" onClick={() => setIsEdit(true)}>
          <i className="fa-solid fa-pencil"></i>
        </div>
      </div>
      <div className="list-button">
        <i className="fa fa-list" aria-hidden="true"></i>
      </div>

      <div className="action">
        <span>{status}</span>
        {item.status !== "in-progress" && item.status !== "completed" && (
          <button onClick={markInProgressHandler} className="in-progress">
            <i className="fa-solid fa-play" /> Start
          </button>
        )}
        {item.status !== "incomplete" && (
          <button onClick={markIncompleteHandler} className="incomplete">
            <i className="fa-solid fa-hourglass-half"></i> Mark as Todo
          </button>
        )}
        {item.status === "in-progress" && (
          <button onClick={markCompletedHandler} className="completed">
            <i className="fa-solid fa-flag-checkered"></i> Completed
          </button>
        )}
        <button onClick={() => setPopup(true)} className="danger">
          <i className="fa-solid fa-trash"></i> Delete
        </button>
      </div>
    </div>
  );
};
