import React from "react";
import { createPortal } from "react-dom";

const Backdrop = ({ children }) => {
  return <div className="backdrop">{children}</div>;
};

export const ConfirmPopup = ({ message, onCancel, onConfirm }) => {
  const PopupContent = (
    <Backdrop>
      <div className="confirm-popup">
        <div>
          <i className="fa-solid fa-triangle-exclamation" />
          <span> {message}</span>
        </div>
        <div className="action">
          <button
            onClick={() => {
              onConfirm?.();
              onCancel?.();
            }}
          >
            Confirm
          </button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </Backdrop>
  );
  return <>{createPortal(PopupContent, document.getElementById("popup"))}</>;
};
