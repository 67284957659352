import React, { useEffect, useState } from "react";
import { TodoItem } from "./TodoItem/TodoItem";

export const TodoList = ({ todoList, updateStatusHandler, isLoading }) => {
  return (
    <div className="todo-list">
      {isLoading && <p>Loading...</p>}
      {!isLoading && !todoList?.length && <p>No todo found</p>}
      {!!todoList?.length &&
        todoList.map((item) => {
          return (
            <TodoItem
              key={`todo-item-${item.id}`}
              item={item}
              onUpdateStatus={updateStatusHandler}
            />
          );
        })}
    </div>
  );
};
