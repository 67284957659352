import React, { useState } from "react";

export const TodoForm = ({ onSubmit, isLoading }) => {
  const [input, setInput] = useState("");
  const clearInput = () => {
    setInput("");
  };
  const submitHandler = (event) => {
    event.preventDefault();
    onSubmit?.(input);
    clearInput();
  };

  console.log({isLoading})

  return (
    <form onSubmit={submitHandler} className="todo-form">
      <div className="input-clearable">
        <input
          value={input}
          onChange={(event) => setInput(event.target.value)}
          placeholder="Enter your todo here"
          required
        ></input>
        {input && (
          <span onClick={clearInput}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </span>
        )}
      </div>

      <button disabled={isLoading} type="submit">
        <i className="fa-solid fa-plus"></i> Add
      </button>
    </form>
  );
};
