import React, { useState } from "react";
import { ConfirmPopup } from "../ConfirmPopup/ConfirmPopup";
import useHttp from "../../hooks/useHttp";
import { isAuthenticate } from "../../utils/auth";
import { BASE_HOST_URL } from "../../utils/constant";

export const Toolbar = ({ setTodoList, initData, setSaveState }) => {
  const [sortState, setSortState] = React.useState("intermediate");
  const [popup, setPopup] = useState(false);
  const { sendRequest: deleteCompletedTodoRequest } = useHttp();

  const clearCompletedHandler = async () => {
    setTodoList((prev) => prev.filter((item) => item.status !== "completed"));
    if (isAuthenticate()) {
      return await deleteCompletedTodoRequest({
        url: `${BASE_HOST_URL}/todo/delete-completed`,
        method: "DELETE",
      });
    }
    return setSaveState?.();
  };

  const sortHandler = () => {
    setTodoList((prev) => {
      const incomplete = prev.filter((item) => item.status === "incomplete");
      const inProgress = prev.filter((item) => item.status === "in-progress");
      const completed = prev.filter((item) => item.status === "completed");
      if (sortState === "intermediate") {
        setSortState("ascending");
        return [...incomplete, ...inProgress, ...completed];
      }
      if (sortState === "ascending") {
        setSortState("descending");
        return [...completed, ...inProgress, ...incomplete];
      }
      if (sortState === "descending") {
        setSortState("intermediate");
        return initData;
      }
    });
  };

  const changeFilterHandler = (event) => {
    const filter = event.target.value;
    setSortState("intermediate");
    if (filter === "all") {
      setTodoList(initData);
    } else {
      setTodoList(initData.filter((item) => item.status === filter));
    }
  };

  return (
    <div className="toolbar">
      {popup && (
        <ConfirmPopup
          message={"Are you sure to delete all completed task?"}
          onConfirm={clearCompletedHandler}
          onCancel={() => setPopup(false)}
        />
      )}
      <button title="Clear all completed todo" onClick={() => setPopup(true)}>
        <i className="fa-solid fa-trash"></i>
      </button>
      <button title="Sort by status" onClick={sortHandler}>
        {sortState === "intermediate" && <i className="fa-solid fa-sort"></i>}
        {sortState === "ascending" && <i className="fa-solid fa-sort-up"></i>}
        {sortState === "descending" && (
          <i className="fa-solid fa-sort-down"></i>
        )}
      </button>
      <select onChange={changeFilterHandler}>
        <option value="all">All</option>
        <option value="incomplete">Incomplete</option>
        <option value="in-progress">In Progress</option>
        <option value="completed">Completed</option>
      </select>
    </div>
  );
};
