import React, { useState } from "react";
import { AuthForm } from "../components/AuthForm/AuthForm";
import { Link, useNavigate } from "react-router-dom";
import { BASE_HOST_URL } from "../utils/constant";
import useHttp from "../hooks/useHttp";

const Register = () => {
  const { sendRequest, error, isLoading } = useHttp();
  const navigate = useNavigate();
  const [success, setSuccess] = useState("");

  const registerHandler = async (username, password) => {
    await sendRequest(
      {
        url: `${BASE_HOST_URL}/register`,
        body: JSON.stringify({ username, password }),
        method: "POST",
      },
      () => {
        setSuccess("Register Successfully!");
        setTimeout(() => {
          navigate("/login");
          setSuccess("");
        }, 1500);
      }
    );
  };
  
  const statusDisplay = (
    <div className={`${error && "error"} ${success && "success"}`}>
      {error || success || ""}
    </div>
  );

  return (
    <AuthForm
      title={"Register"}
      buttonLabel={"Register" + (isLoading ? "..." : "")}
      subChild={
        <div>
          {statusDisplay}
          <Link to={"/login"}>Already has an account?</Link>
        </div>
      }
      onSubmit={registerHandler}
      isLoading={isLoading}
    />
  );
};

export default Register;
