import React, { useState } from "react";
import { Link } from "react-router-dom";

export const AuthForm = ({
  title,
  buttonLabel = "Submit",
  subChild = "",
  onSubmit,
  isLoading,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (event) => {
    event.preventDefault();
    onSubmit?.(username, password);
  };

  return (
    <form className="auth-form" onSubmit={handleLogin}>
      <Link to="/" className="back-home-btn">
        <i class="fa-solid fa-arrow-left"></i>
      </Link>
      <h1>{title}</h1>
      <div className="input-group">
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      {/* <button type="submit" className={`${isLoading && 'disable'}`}> */}
      <button disabled={isLoading} type="submit">
        {buttonLabel}
      </button>
      {subChild}
    </form>
  );
};
