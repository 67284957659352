import { useState } from "react";

const useHttp = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /**
   *
   * @param {Object} config - The configuration object for the HTTP request.
   * @param {string} config.url - The URL to send the request to.
   * @param {string} [config.method="GET"] - The HTTP method to use for the request.
   * @param {Object} [config.headers] - The headers to include in the request.
   * @param {Object} [config.body] - The body of the request, should be stringify.
   * @param {Function} [onSuccess] - A callback to run when the request is successful.
   * @returns {Promise<Response>} - A promise that resolves to the response from the server.
   */
  const sendRequest = async (config, onSuccess) => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await fetch(config.url, {
        method: config.method ? config.method : "GET",
        headers: config.headers
          ? config.headers
          : {
              "Content-Type": "application/json",
              Accept: "application/json, image/*",
              Bearer: localStorage.getItem("sessionToken") || "",
            },
        body: config.body ? config.body : null,
        credentials: "include",
      });
      const data = await response.json();
      if (response.status >= 200 && response.status < 300) {
        if (onSuccess) {
          onSuccess(data);
        }
      } else {
        setError(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const cancelRequest = () => {
    setIsLoading(false);
  };

  return {
    error,
    setError,
    isLoading,
    sendRequest,
    cancelRequest,
  };
};

export default useHttp;
