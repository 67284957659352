export const persistToken = (token) => {
    localStorage.setItem("sessionToken", token);
    localStorage.setItem("Expired", new Date().getTime() + 2592000000); // 30 days
}

export const removeToken = () => {
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("Expired");
}

export const isAuthenticate = () => {
    return localStorage.getItem("sessionToken") && localStorage.getItem("Expired") > new Date().getTime();
}