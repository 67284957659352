import React, { useState } from "react";
import { AuthForm } from "../components/AuthForm/AuthForm";
import { Link, useNavigate } from "react-router-dom";
import { BASE_HOST_URL } from "../utils/constant";
import useHttp from "../hooks/useHttp";
import { persistToken } from "../utils/auth";

const Login = () => {
  const { sendRequest, error, isLoading } = useHttp();
  const navigate = useNavigate();

  const loginHandler = async (username, password) => {
    await sendRequest(
      {
        url: `${BASE_HOST_URL}/login`,
        body: JSON.stringify({ username, password, role: "user" }),
        method: "POST",
      },
      (data) => {
        persistToken(data.sessionToken);
        navigate("/");
      }
    );
  };

  const statusDisplay = (
    <div className={`${error && "error"}`}>{error || ""}</div>
  );

  return (
    <AuthForm
      title={"Login"}
      buttonLabel="Login"
      subChild={
        <div>
          {statusDisplay}
          <Link to={"/register"}>or Register?</Link>
        </div>
      }
      onSubmit={loginHandler}
      isLoading={isLoading}
    />
  );
};

export default Login;
