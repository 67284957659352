import React from "react";
import { TodoList } from "../components/TodoList/TodoList";
import { TodoForm } from "../components/TodoForm/TodoForm";
import { useEffect, useReducer, useState } from "react";
import { getData, saveData } from "../utils/data";
import { Toolbar } from "../components/Toolbar/Toolbar";
import Header from "../components/Header/Header";
import { isAuthenticate } from "../utils/auth";
import useHttp from "../hooks/useHttp";
import { BASE_HOST_URL } from "../utils/constant";

export const Home = () => {
  const [todoList, setTodoList] = useState([]);
  const [initData, setInitData] = useState([]);
  const [saveState, setSaveState] = useReducer(() => ({}), {});
  const { sendRequest, isLoading } = useHttp();
  const { sendRequest: addTodoRequest, isLoading: isAdding } = useHttp();
  const { sendRequest: deleteTodoRequest } = useHttp();
  const { sendRequest: updateTodoRequest } = useHttp();

  const addTodoHandler = async (title) => {
    if (isAuthenticate()) {
      await addTodoRequest(
        {
          url: `${BASE_HOST_URL}/todo/create`,
          method: "POST",
          body: JSON.stringify({ title }),
        },
        (todo) => {
          setTodoList((prev) => [{ ...todo }, ...prev]);
          setInitData((prev) => [{ ...todo }, ...prev]);
        }
      );
    } else {
      setTodoList((prev) => [
        { id: new Date().getTime(), title, status: "incomplete" },
        ...prev,
      ]);
      setSaveState();
    }
  };

  const updateStatusHandler = async (id, status, title) => {
    if (status === "delete") {
      setTodoList((prev) => prev.filter((item) => item.id !== id));
      setInitData((prev) => prev.filter((item) => item.id !== id));
      if (isAuthenticate()) {
        return await deleteTodoRequest({
          url: `${BASE_HOST_URL}/todo/delete/${id}`,
          method: "DELETE",
        });
      }
      return setSaveState();
    }

    if (status === "update") {
      setTodoList((prev) =>
        prev.map((item) => (item.id === id ? { ...item, title } : item))
      );
      setInitData((prev) =>
        prev.map((item) => (item.id === id ? { ...item, title } : item))
      );
      if (isAuthenticate()) {
        return await updateTodoRequest({
          url: `${BASE_HOST_URL}/todo/update-title`,
          method: "PUT",
          body: JSON.stringify({ id, title }),
        });
      }
      return setSaveState();
    }

    setTodoList((prev) =>
      prev.map((item) => (item.id === id ? { ...item, status } : item))
    );
    setInitData((prev) =>
      prev.map((item) => (item.id === id ? { ...item, status } : item))
    );
    if (isAuthenticate()) {
      return await updateTodoRequest({
        url: `${BASE_HOST_URL}/todo/update`,
        method: "PUT",
        body: JSON.stringify({ id, status }),
      });
    }
    return setSaveState();
  };

  useEffect(() => {
    (async () => {
      if (isAuthenticate()) {
        await sendRequest({ url: `${BASE_HOST_URL}/todo/all` }, (data) => {
          setTodoList(data);
          setInitData(data);
        });
      } else {
        const data = await getData();
        setTodoList(data);
        setInitData(data);
      }
    })();
  }, []);

  useEffect(() => {
    if (!isAuthenticate()) {
      if (todoList.length) {
        saveData(todoList);
        setInitData(todoList);
      } else {
        if (initData.length) {
          saveData(todoList);
        }
      }
    }
  }, [saveState]);

  return (
    <>
      <Header />
      <div className="container">
        <TodoForm onSubmit={addTodoHandler} isLoading={isAdding} />
        <Toolbar
          setTodoList={setTodoList}
          initData={initData}
          setSaveState={setSaveState}
        />
        <TodoList
          isLoading={isLoading}
          todoList={todoList}
          updateStatusHandler={updateStatusHandler}
        />
      </div>
    </>
  );
};
